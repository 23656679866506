<template>
  <v-card v-if="loading === false" width="100%">
    <v-card-title>
      {{ this.company.name }}
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="12" md="6">
           <v-list dense>
            <v-list-item v-for="item in companyInformation" :key="item.label">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.label }}</v-list-item-title>
                <v-list-item-subtitle>{{ getCompanyProperty(item.propertyName) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>

        <v-col cols="12" md="6">
           <v-list dense>
            <v-list-item v-for="item in userInformation" :key="item.label">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.label }}</v-list-item-title>
                <v-list-item-subtitle>{{ getUserProperty(item.propertyName) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <edit-temp-company-dialog :tempCompany="company" v-if="loading === false" @update="updateParentComponent"/>

      <add-temp-company-api-key-dialog
        v-bind:tempCompanyId="company.id"
        v-if="company !== null"
        @addApiKeySuccess="closeWindow"
      />
    </v-card-actions>
  </v-card>
</template>

<script>
import EditTempCompanyDialog from './EditTempCompanyDialog.vue';
import * as utils from '../utils.js';
import AddTempCompanyApiKeyDialog from './AddTempCompanyApiKeyDialog.vue';

export default {
  name: 'TempCompanyDetails',
  components: {
    AddTempCompanyApiKeyDialog,
    EditTempCompanyDialog
  },
  data: function () {
    return {
      loading: false,
      companyInformation: [
        { label: "ID", propertyName: 'id', icon: "mdi-fingerprint" },
        { label: "PIB", propertyName: 'tid', icon: "mdi-fingerprint" },
        { label: "MB", propertyName: 'mn', icon: "mdi-fingerprint" },
        { label: "Država", propertyName: 'country', icon: "mdi-flag" },
        { label: "Grad", propertyName: 'town', icon: "mdi-city-variant" },
        { label: "Poštanski broj", propertyName: 'postalCode', icon: 'mdi-mailbox' },
        { label: "Adresa", propertyName: 'address', icon: "mdi-map-marker" },
        { label: "Datum dodavanja", propertyName: 'createdAt', icon: "mdi-calendar" },
      ],
      userInformation: [
        { label: "FirebaseID", propertyName: "firebaseId", icon: "mdi-firebase" },
        { label: "Ime", propertyName: "firstName", icon: "mdi-account" },
        { label: "Prezime", propertyName: "lastName", icon: "mdi-account" },
        { label: "Email za login", propertyName: "username", icon: "mdi-at" },
        { label: "Kontakt email", propertyName: "contactEmail", icon: "mdi-card-account-mail" },
        { label: "Broj telefona", propertyName: "phone", icon: "mdi-phone" },
      ]
    }
  },
  props: {
    company: {
      type: Object,
      required: true
    }
  },
  methods: {
    getCompanyProperty(propertyName) {
      if (propertyName === 'createdAt')
        return utils.formatDate(this.company[propertyName]);
      else
        return this.company[propertyName];
    },
    getUserProperty(propertyName) {
      if (propertyName === 'createdAt')
        return utils.formatDate(this.company[propertyName]);
      else
        return this.company[propertyName];
    },
    updateParentComponent (companyId) {
      this.$emit('updateCompany', companyId);
    },
    closeWindow() {
      window.close();
    }
  }
}
</script>
