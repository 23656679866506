<template>
  <v-container class="mt-14">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="10">
        <v-row align-center column justify-center>
          <users-list class="mb-10"/>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UsersList from '../components/UsersList.vue'

export default {
  name: "UsersPage",
  components: {
    UsersList
  }
}
</script>
