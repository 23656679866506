<template>
  <v-card v-if="loading === false" width="100%" class="ml-7">
    <v-card-title>
      API ključ
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="12" md="12" class="ma-0 pa-0">
           <v-list dense>
            <v-list-item>
              <v-list-item-icon>
                <v-icon> mdi-key </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Produkcioni API ključ</v-list-item-title>
                <v-list-item-subtitle> {{ company.prodApiKey }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon>
                <v-icon> mdi-key </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Demo API ključ</v-list-item-title>
                <v-list-item-subtitle> {{ company.demoApiKey }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>

      <v-row class="ma-0 pa-0">
        <v-col cols="12" md="6" class="ma-0 pa-0">
           <v-switch hide-details="auto" disabled class="disable-events" label="Demo SEF?" :input-value="company.isDemo"></v-switch>
        </v-col>

        <v-col cols="12" md="6" class="mt-4 mb-0 pa-0">
          <v-chip text-color="white" :color="companyApiKeyValidatedColor">
            {{ companyApiKeyValidatedTextValue }}
          </v-chip>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <edit-company-api-key-dialog :company="company" v-if="loading === false" @update="updateParentComponent"/>
    </v-card-actions>
  </v-card>
</template>

<script>
import EditCompanyApiKeyDialog from './EditCompanyApiKeyDialog.vue';
import * as utils from '../utils.js';

export default {
  name: 'CompanyApiKeyDetails',
  components: {
    EditCompanyApiKeyDialog
  },
  data: function () {
    return {
      loading: false,
    }
  },
  props: {
    company: {
      type: Object,
      required: true
    }
  },
  computed: {
    companyApiKeyValidatedTextValue() {
      return utils.getCompanyApiKeyValidatedTextValue(this.company.validated)
    },
    companyApiKeyValidatedColor() {
      switch (this.company.validated) {
        case 0:
          return "orange";
        case 1:
          return "red";
        case 2:
          return "green";
        default:
          return "grey";
      }
    }
  },
  methods: {
    getCompanyProperty(propertyName) {
      if (propertyName === 'createdAt')
        return utils.formatDate(this.company[propertyName]);
      else
        return this.company[propertyName];
    },
    updateParentComponent() {
      this.$emit('updateCompany', this.company.id);
    }
  }
}
</script>
