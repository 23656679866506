import * as fetch from './fetch'

export function getCompany(id, token) {
  const endpoint = '/companies/' + id
  return fetch.GET(endpoint, token, true)
}

export function getCompanySubscription(id, token) {
  const endpoint = '/companies/' + id + '/subscription';
  return fetch.GET(endpoint, token, true)
}

export function fullRegistration(fullRegistrationRequest, token) {
  const endpoint = '/companies/fullRegistration';
  return fetch.POST(endpoint, token, fullRegistrationRequest);
}

export function addCompanyKey(companyId, companyKeyRequest, token) {
  const endpoint = `/companies/${companyId}/apiKey`;
  return fetch.POST(endpoint, token, companyKeyRequest);
}

export function changeCompanyKeys(companyId, companyKeyRequest, token) {
  const endpoint = `/companies/${companyId}/apiKey`;
  return fetch.PUT(endpoint, token, companyKeyRequest);
}

export function getList(options, token) {
  let parameters = mapToParameters(options)

  const endpoint = '/companies'
  let queryString = new URLSearchParams(parameters).toString()
  return fetch.GET(endpoint + '?' + queryString, token, true)
}

export function getTempCompaniesList(options, token) {
  let parameters = mapToParameters(options)

  const endpoint = '/companies/temp';
  let queryString = new URLSearchParams(parameters).toString();
  return fetch.GET(endpoint + '?' + queryString, token, true);
}

export function getTempCompany(tempCompanyId, token) {
  const endpoint = `/companies/temp/${tempCompanyId}`;
  return fetch.GET(endpoint, token, true);
}

export function addTempCompanyKey(tempCompanyId, companyKeyRequest, token) {
  const endpoint = `/companies/temp/${tempCompanyId}/apiKey`;
  return fetch.POST(endpoint, token, companyKeyRequest);
}

export function putTempCompany(tempCompanyId, updatedCompany, token) {
  const endpoint = `/companies/temp/${tempCompanyId}`;
  return fetch.PUT(endpoint, token, updatedCompany);
}

function mapToParameters(options) {
  let parameters = {}

  if (options.page !== null) {
    parameters.pageNum = options.page
  }

  if (options.itemsPerPage !== null) {
    parameters.pageSize = options.itemsPerPage
  }

  if (options.search) {
    parameters.query = options.search
  }

  return parameters
}
