<template>
   <v-card width="100%" class="ml-7">
    <v-card-title>
      Pretplata
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="6">
          <v-select :items="monthsOptions" v-model="monthsForTrial" item-text="text" item-value="value" label="Broj meseci" required outlined hide-details></v-select>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <v-spacer />
        <v-btn class="primary white--text" text @click="onClickProlong" :loading="postingToApi">
          Pokreni Trial
          <v-icon right>mdi-plus</v-icon>
        </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import * as apiSubscriptions from '../api/subscriptions.js';

export default {
  name: 'CompanySubscriptionStartTrial',
  data: function () {
    return {
      postingToApi: false,
      monthsOptions: [
        { value: 3, text: "3 meseca" },
        { value: 6, text: "6 meseci" },
        { value: 12, text: "12 meseci" },
      ],
      monthsForTrial: null,
    }
  },
  props: {
    companyId: {
      type: Number,
      required: true
    }
  },
  methods: {
    onClickProlong () {
      this.postingToApi = true;

      let request = {
        companyId: this.companyId,
        months: this.monthsForTrial
      }

      apiSubscriptions.startTrial(request, this.$store.getters.token)
        .then(response => response)
        .then(() => {
          // loading na dugmetu
          this.postingToApi = false;

          // prikaz snackbar obavestenja
          this.snackbarText = `Uspešno uključena TRIAL pretplata za ${this.monthsForTrial} meseci`;
          this.snackbar = true;

          this.$emit('updateSubscription');
        })
        .catch(() => {
          this.postingToApi = false;
        });
    },
  }
}
</script>
