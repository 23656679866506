import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import firebase from 'firebase/app'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

// SefLink WEB
// const firebaseConfig = {
//   apiKey: "AIzaSyDNep8tCBFDPkBnuRg9VGC735w9eBtc6dg",
//   authDomain: "seflink-ea1b1.firebaseapp.com",
//   projectId: "seflink-ea1b1",
//   storageBucket: "seflink-ea1b1.appspot.com",
//   messagingSenderId: "781551156761",
//   appId: "1:781551156761:web:5d9e118e37fa39cc443488",
//   measurementId: "G-JGCFLPS23C"
// };

// SefLink Admin Portal
const firebaseConfig = {
  apiKey: "AIzaSyDNep8tCBFDPkBnuRg9VGC735w9eBtc6dg",
  authDomain: "seflink-ea1b1.firebaseapp.com",
  projectId: "seflink-ea1b1",
  storageBucket: "seflink-ea1b1.appspot.com",
  messagingSenderId: "781551156761",
  appId: "1:781551156761:web:04c75f348879d3e6443488",
  measurementId: "G-QC2HNBRM79"
};

firebase.initializeApp(firebaseConfig);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
