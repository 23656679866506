<template>
  <v-container>
    <v-card>
      <v-card-title>
        Pretplate koje uskoro ističu
      </v-card-title>
      <v-card-subtitle>
        <v-row justify="space-between">
          <v-col cols="12" sm="4" class="pt-5">
            <v-slider
              label="Broj dana"
              max="60"
              min="10"
              :thumb-size="24"
              thumb-label="always"
              v-model="days"
            ></v-slider>
          </v-col>
          <v-col cols="12" sm="2" class="pt-4">
            <v-btn class="primary" text @click="onEnterSearch" :loading="loading">
              <v-icon>mdi-magnify</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" sm="2" class="py-0">
          </v-col>
          <v-col cols="12" sm="4" class="py-0">
            <v-text-field
              v-model="queryString"
              append-icon="mdi-magnify"
              label="Pretraga"
              single-line
              hide-details
              clearable
              @keyup.enter.prevent='onEnterSearch'
              @click:clear='onClearSearch()'
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-subtitle>
      <v-data-table
      :headers="headers"
      :items="listOfSubscriptions"
      :loading="loading"
      :search="queryString"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50],
        'items-per-page-text': 'Broj redova po stranici:'
      }"
      no-data-text="Nema rezultata"
      loading-text="Učitavanje podataka... Molimo sačekajte"
      class="elevation-1 row-pointer"
      width="100%"
      @click:row="onRowClick"
      >
        <template v-slot:[`item.state`]="{ item }">
          {{ getSubscriptionStateText(item.state) }}
        </template>

        <template v-slot:[`item.validUntil`]="{ item }">
          {{ formatDate(item.validUntil) }}
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import * as apiSubscriptions from '../api/subscriptions.js';
import * as utils from '../utils.js';

export default {
  name: "ExpiringSubscriptionsList",
  data: function () {
    return {
      loading: false,
      queryString: null,
      listOfSubscriptions: [],
      days: 10,
      headers: [
          { text: 'ID', value: 'company.id', width: "5%", sortable: false },
          { text: 'PIB', value: 'company.tid', width: "8%", sortable: false },
          { text: 'MB', value: 'company.mn', width: "7%", sortable: false },
          { text: 'Naziv', value: 'company.name', width: "25%", sortable: false },
          { text: 'Adresa', value: 'company.address', width: "20%", sortable: false },
          { text: 'Grad', value: 'company.town', width: "10%", sortable: false },
          { text: 'Status', value: 'state', width: "10%", sortable: false },
          { text: 'Trajanje pretplate', value: 'validUntil', width: "8%", sortable: false },
      ]
    }
  },
  mounted () {
    this.getExpiringSubscriptionsList()
  },
  methods: {
    getExpiringSubscriptionsList() {
      this.loading = true

      apiSubscriptions.getExpiringSubscriptionsList(this.days, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          this.loading = false;
          this.listOfSubscriptions = responseData;
        })
        .catch(() => {
          this.listOfSubscriptions = [];
          this.loading = false;
        })
    },

    getSubscriptionStateText(subscriptionState) {
      return utils.getSubscriptionStateText(subscriptionState);
    },

    formatDate(dateStr) {
      return utils.formatDate(dateStr);
    },

    onEnterSearch() {
      this.getExpiringSubscriptionsList();
    },

    onClearSearch() {
      this.queryString = '';
    },

    onRowClick(subscription) {
      let route = this.$router.resolve({ path: '/companies/' + subscription.company.id });
      window.open(route.href, '_blank');
    },

    onSwitchSearch() {
      this.options.page = 1;
      this.options.isSearchByID = this.isSearchByID;
      this.options.search = this.queryString;
      this.getExpiringSubscriptionsList();
    },
  }
}
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
