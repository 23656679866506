var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-card',[_c('v-card-title',[_vm._v(" Pretplate koje uskoro ističu ")]),_c('v-card-subtitle',[_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{staticClass:"pt-5",attrs:{"cols":"12","sm":"4"}},[_c('v-slider',{attrs:{"label":"Broj dana","max":"60","min":"10","thumb-size":24,"thumb-label":"always"},model:{value:(_vm.days),callback:function ($$v) {_vm.days=$$v},expression:"days"}})],1),_c('v-col',{staticClass:"pt-4",attrs:{"cols":"12","sm":"2"}},[_c('v-btn',{staticClass:"primary",attrs:{"text":"","loading":_vm.loading},on:{"click":_vm.onEnterSearch}},[_c('v-icon',[_vm._v("mdi-magnify")])],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"2"}}),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","sm":"4"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Pretraga","single-line":"","hide-details":"","clearable":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.onEnterSearch($event)},"click:clear":function($event){return _vm.onClearSearch()}},model:{value:(_vm.queryString),callback:function ($$v) {_vm.queryString=$$v},expression:"queryString"}})],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1 row-pointer",attrs:{"headers":_vm.headers,"items":_vm.listOfSubscriptions,"loading":_vm.loading,"search":_vm.queryString,"footer-props":{
      'items-per-page-options': [10, 20, 30, 40, 50],
      'items-per-page-text': 'Broj redova po stranici:'
    },"no-data-text":"Nema rezultata","loading-text":"Učitavanje podataka... Molimo sačekajte","width":"100%"},on:{"click:row":_vm.onRowClick},scopedSlots:_vm._u([{key:"item.state",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getSubscriptionStateText(item.state))+" ")]}},{key:"item.validUntil",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.validUntil))+" ")]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }