<template>
  <div>
    <v-dialog v-model="dialogAddNewUser" @keydown.esc="onCancel" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="primary mb-3" dark v-bind="attrs" v-on="on">
          Dodaj korisnika
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-card-title> Dodavanje korisnika </v-card-title>

        <v-card-text class="mt-4">
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" class="pt-0 pb-0">
                <v-text-field label="Ime" required outlined v-model="newUser.firstName" :rules="[rules.required]"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="pt-0 pb-0">
                <v-text-field label="Prezime" required outlined v-model="newUser.lastName" :rules="[rules.required]"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="pt-0 pb-0">
                <v-text-field label="Telefon" outlined v-model="newUser.phone"></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="pt-0 pb-0">
                <v-text-field label="Kontakt email" outlined v-model="newUser.contactEmail"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" class="pt-0 pb-0">
                <v-text-field label="Email za login" outlined v-model="newUser.email" :rules="[rules.required]"></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" class="pt-0 pb-0">
                <v-text-field
                  label="Lozinka"
                  required
                  outlined
                  v-model="newUser.password"
                  :append-icon="hidePassword ? 'mdi-eye-off' : 'mdi-eye'"
                  :type="hidePassword ? 'password' : 'text'"
                  @click:append="hidePassword = !hidePassword"
                  :rules="[rules.required, rules.min]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="pt-0 pb-0">
                <v-select :items="roles" v-model="newUser.role" item-text="text" item-value="value" label="Uloga" required outlined></v-select>
              </v-col>
              <v-col cols="12" sm="6" class="pt-0 pb-0">
                <v-switch label="Admin?" outlined v-model="newUser.isAdmin"></v-switch>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error ma-2 white--text" text @click="onCancel">
            Odustani
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn class="primary ma-2 white--text" text @click="onAddUser" :loading="postingToApi">
            Dodaj korisnika
            <v-icon right>mdi-plus</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="5000" color="success">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn text icon v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import * as apiUsers from '../api/users.js'

export default {
  name: "AddUserToCompanyDialog",
  data: function () {
    return {
      dialogAddNewUser: false,
      postingToApi: false,

      newUser: {
        role: 1,
        password: '',
      },
      hidePassword: false,
      roles: [
        { value: 0, text: "Vlasnik" },
        { value: 1, text: "Zaposleni" },
      ],
      rules: {
        required: value => !!value || 'Obavezno polje',
        min: v => v.length >= 6 || 'Min 6 karaktera',
      },

      snackbar: false,
      snackbarText: "",
    }
  },
  props: {
    company: {
      type: Object,
      required: true
    },
  },
  methods: {
    onAddUser () {
      this.postingToApi = true;

      apiUsers.addUserToCompany(this.company.id, this.newUser, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          // loading na dugmetu
          this.postingToApi = false;

          // zatvaranje modalnog prozora
          this.dialogAddNewUser = false;

          // prikaz snackbar obavestenja
          this.snackbarText = `Uspešno dodat korisnik ID ${responseData.id}`;
          this.snackbar = true;

          this.$emit('updateUsersList');
        })
        .catch(() => {
          this.postingToApi = false;
        });
    },
    onCancel () {
      this.dialogAddNewUser = false;
    },
  }
}
</script>
