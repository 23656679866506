<template>
  <div class="ma-3 pa-0">
    <v-dialog v-model="dialogNewCompanyKey" @keydown.esc="onCancel" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="primary" dark v-bind="attrs" v-on="on">
          Dodaj API ključ
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="onCancel">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            Dodavanje API ključa
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text class="mt-4">
          <v-container>
            <v-row>
              <v-col cols="12" class="pt-0 pb-0">
                <v-text-field label="API ključ" required outlined v-model="newCompanyKey.apiKey"></v-text-field>
              </v-col>

              <v-col cols="12" sm="6" class="pt-0 pb-0">
                <v-switch label="Demo SEF?" outlined v-model="newCompanyKey.isDemo"></v-switch>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error ma-2 white--text" text @click="onCancel">
            Odustani
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn class="primary ma-2 white--text" text @click="onAddCompanyKey" :loading="postingToApi">
            Dodaj
            <v-icon right>mdi-plus</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="5000" color="success">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn text icon v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import * as apiCompanies from '../api/companies.js';

export default {
  name: "AddCompanyApiKeyDialog",
  data: function () {
    return {
      dialogNewCompanyKey: false,
      postingToApi: false,
      newCompanyKey: {
        apiKey: null,
        isDemo: false
      },
      snackbar: false,
      snackbarText: ""
    }
  },
  props: {
    companyId: {
      type: Number,
      required: true
    }
  },
  methods: {
    onAddCompanyKey () {
      this.postingToApi = true;

      apiCompanies.addCompanyKey(this.companyId, this.newCompanyKey, this.$store.getters.token)
        .then(response => response)
        .then(() => {
          // loading na dugmetu
          this.postingToApi = false;

          // zatvaranje modalnog prozora
          this.dialogNewCompanyKey = false;

          // prikaz snackbar obavestenja
          this.snackbarText = `Uspešno dodat API ključ za kompaniju ID: ${this.companyId}`;
          this.snackbar = true;


          // brisanje unetih podataka za novu kompaniju
          this.newCompanyKey = {};

          this.updateParentComponent();
        })
        .catch(() => {
          this.postingToApi = false;
        });
    },
    onCancel () {
      this.dialogNewCompanyKey = false;
      this.newCompanyKey = {};
    },
    updateParentComponent () {
      this.$emit('updateCompany', this.companyId);
    },
  }
}
</script>
