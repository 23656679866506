
export function getCompanyInfo(tid) {
  const requestOptions = {
    method: 'GET'
  };

  // do the fetch
  return fetch('https://api.seflink.rs:5003/api/nbs/companyInfo/' + tid, requestOptions)
    .then(response => {
      if (response.ok) {
        return response;
      } else {
        throw response;
      }
    })
}
