<template>
  <v-container class="mt-14">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="12">
        <v-row align-center column justify-center>
          <v-row class="mt-2 mb-2">
            <add-company-dialog />
          </v-row>
          <companies-list />
        </v-row>
      </v-col>

      <v-col cols="12" sm="12">
        <v-row align-center column justify-center>
          <temp-companies-list />
        </v-row>
      </v-col>

      <v-col cols="12" sm="10">
        <v-row align-center column justify-center>
          <expiring-subscriptions-list />
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CompaniesList from '../components/CompaniesList.vue'
import AddCompanyDialog from '../components/AddCompanyDialog.vue'
import TempCompaniesList from '../components/TempCompaniesList.vue'
import ExpiringSubscriptionsList from '../components/ExpiringSubscriptionsList.vue'

export default {
  name: "CompaniesPage",
  components: {
    CompaniesList,
    AddCompanyDialog,
    TempCompaniesList,
    ExpiringSubscriptionsList
  }
}
</script>
