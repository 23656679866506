<template>
  <div>
    <v-dialog v-model="dialogNewCompany" @keydown.esc="onCancel" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="primary" dark v-bind="attrs" v-on="on">
          Registracija
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>

      <v-stepper non-linear v-model="currentStep">
        <v-stepper-header>
          <v-stepper-step editable step="1" :complete="currentStep > 1">
            Registracija kompanije
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step editable step="2" :complete="currentStep > 2">
            Registracija korisnika
          </v-stepper-step>
        </v-stepper-header>

        <v-stepper-items>
          <v-stepper-content step="1" class="ma-0 pa-0">
            <v-card>
              <v-card-text class="mt-4">
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" class="pt-0 pb-0">
                      <v-text-field label="PIB" required outlined v-model="newCompany.tid" :rules="[rules.required]"></v-text-field>
                    </v-col>
                    <v-col sm="6" class="pt-0 pb-0">
                      <v-btn class="primary ma-2 white--text" @click="getCompanyFromNbs" type="Submit" :loading="loadingCompanyFromNbs" >
                          <v-icon>mdi-magnify</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0">
                      <v-textarea label="Naziv kompanije" required outlined v-model="newCompany.name" rows="3" :rules="[rules.required]"></v-textarea>
                    </v-col>
                    <v-col cols="12" sm="6" class="pt-0 pb-0">
                      <v-text-field label="Matični broj" outlined v-model="newCompany.mn" :rules="[rules.required]"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="8" class="pt-0 pb-0">
                      <v-text-field label="Adresa" outlined v-model="newCompany.address"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" md="4" class="pt-0 pb-0">
                      <v-text-field label="Grad" outlined v-model="newCompany.town"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" class="pt-0 pb-0">
                      <v-text-field label="Poštanski broj" outlined v-model="newCompany.postalCode"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" class="pt-0 pb-0">
                      <v-text-field label="API ključ" outlined v-model="newCompany.apiKey"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" class="pt-0 pb-0">
                      <v-switch label="Demo SEF?" outlined v-model="newCompany.isDemo"></v-switch>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0">
                      <v-alert type="error" v-if="addingFailed">Postoji kompanija sa zadatim PIB-om!</v-alert>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="error ma-2 white--text" text @click="onCancel">
                  Odustani
                  <v-icon right>mdi-close</v-icon>
                </v-btn>
                <v-btn color="primary" @click="currentStep = 2">
                  Dalje
                  <v-icon right>mdi-chevron-right</v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>

          <v-stepper-content step="2">
            <v-card>
              <v-card-text class="mt-4">
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="6" class="pt-0 pb-0">
                      <v-text-field label="Ime" required outlined v-model="newUser.firstName" :rules="[rules.required]"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" class="pt-0 pb-0">
                      <v-text-field label="Prezime" required outlined v-model="newUser.lastName" :rules="[rules.required]"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" class="pt-0 pb-0">
                      <v-text-field label="Telefon" outlined v-model="newUser.phone"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" class="pt-0 pb-0">
                      <v-text-field label="Kontakt email" outlined v-model="newUser.contactEmail"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" class="pt-0 pb-0">
                      <v-text-field label="Email za login" outlined v-model="newUser.email" :rules="[rules.required]"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" class="pt-0 pb-0">
                      <v-text-field
                        label="Lozinka"
                        required
                        outlined
                        v-model="newUser.password"
                        :append-icon="hidePassword ? 'mdi-eye-off' : 'mdi-eye'"
                        :type="hidePassword ? 'password' : 'text'"
                        @click:append="hidePassword = !hidePassword"
                        :rules="[rules.required, rules.min]"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" class="pt-0 pb-0">
                      <v-select :items="roles" v-model="newUser.role" item-text="text" item-value="value" label="Uloga" required outlined></v-select>
                    </v-col>
                    <v-col cols="12" class="pt-0 pb-0">
                      <v-alert type="error" v-if="addingFailed">Postoji kompanija sa zadatim PIB-om!</v-alert>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="error ma-2 white--text" text @click="onCancel">
                  Odustani
                  <v-icon right>mdi-close</v-icon>
                </v-btn>
                <v-btn class="primary ma-2 white--text" text @click="fullRegistration" :loading="fullRegistrationInProgress">
                  Registracija
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-dialog>

    <v-snackbar v-model="snackbar" :timeout="5000" color="success">
      {{ snackbarText }}
      <template v-slot:action="{ attrs }">
        <v-btn text icon v-bind="attrs" @click="snackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import * as companiesApi from '../api/companies';
import * as apiNbs from '../api/nbs';

export default {
  name: "AddCompanyDialog",
  data: function () {
    return {
      dialogNewCompany: false,

      addingCompanyInProgress: false,
      loadingCompanyFromNbs: false,
      fullRegistrationInProgress: false,

      currentStep: 1,
      newCompany: {
        isDemo: false
      },
      newUser: {
        role: 0
      },
      hidePassword: false,
      snackbar: false,
      snackbarText: "",
      roles: [
        { value: 0, text: "Vlasnik" },
        { value: 1, text: "Zaposleni" },
      ],
      rules: {
        required: value => !!value || 'Obavezno polje',
        min: v => v.length >= 6 || 'Min 6 karaktera',
      },

      addingFailed: false
    }
  },
  methods: {
    fullRegistration() {
      this.fullRegistrationInProgress = true;

      let fullRegistationRequest = this.makeFullRegistrationRequest();
      companiesApi.fullRegistration(fullRegistationRequest, this.$store.getters.token)
        .then(response => response)
        .then(() => {
          // loading na dugmetu
          this.fullRegistrationInProgress = false;

          // zatvaranje modalnog prozora
          this.dialogNewCompany = false;

          // prikaz snackbar obavestenja
          this.snackbarText = "Uspešno dodati kompanija i korisnik";
          this.snackbar = true;

          // brisanje unetih podataka za novu kompaniju
          this.newCompany = {};
          this.newUser = {};

          this.$emit('updateListOfCompanies');
        })
        .catch((error) => {
          if (error.message && error.message.startsWith("Company with PIB")) {
            this.showError();
          }

          this.fullRegistrationInProgress = false;
        });
    },
    makeFullRegistrationRequest() {
      let req = {
        name: this.newCompany.name,
        fullName: this.newCompany.name,
        tid: this.newCompany.tid,
        mn: this.newCompany.mn,
        postalCode: this.newCompany.postalCode,
        address: this.newCompany.address,
        town: this.newCompany.town,
        country: this.newCompany.country,
        apiKey: this.newCompany.apiKey,
        isDemo: this.newCompany.isDemo,
        firstName: this.newUser.firstName,
        lastName: this.newUser.lastName,
        email: this.newUser.email,
        contactEmail: this.newUser.contactEmail,
        phone: this.newUser.phone,
        password: this.newUser.password,
        role: this.newUser.role
      };

      return req;
    },
    getCompanyFromNbs() {
      this.loadingCompanyFromNbs = true;
      apiNbs.getCompanyInfo(this.newCompany.tid)
        .then((response) => response.json())
        .then((responseData) => {
          // loading na dugmetu
          this.loadingCompanyFromNbs = false;
          this.newCompany.name = responseData.companyName;
          this.newCompany.mn = responseData.nationalIdentificationNumber;
          this.newCompany.address = responseData.address;
          this.newCompany.town = responseData.city;
          this.newCompany.postalCode = responseData.postalCode;
        })
        .catch((error) => {
          if (error.message) {
            this.showError(error.message);
          }

          this.loadingCompanyFromNbs = false;
        });
    },
    onCancel () {
      this.dialogNewCompany = false;
      this.newCompany = {};
    },
    showError() {
      this.addingFailed = true;

      setTimeout(() => {
        this.addingFailed = false;
      }, 5000)
    }
  }
}
</script>
