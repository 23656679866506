<template>
  <v-container class="mt-5 ml-5">
    <v-row align="start" justify="start">
      <v-col cols="12" lg="6" sm="6">
        <v-row align-center column justify-center>
          <v-row class="mt-2 mb-2"  v-if="loading === true">
            <v-progress-circular indeterminate>
            </v-progress-circular>
          </v-row>

          <company-details
            v-bind:company="company" v-if="finishedLoading === true"
            @updateCompany="getCompany"
          />
        </v-row>
      </v-col>

      <v-col cols="12" lg="4" sm="6">
        <v-row align="center" column justify="center">
          <v-row class="mt-2 mb-2"  v-if="loading === true">
            <v-progress-circular indeterminate>
            </v-progress-circular>
          </v-row>

          <company-api-key-details
            v-bind:company="company"
            v-if="finishedLoading === true && (company.prodApiKey !== null || company.demoApiKey !== null)"
            @updateCompany="getCompany"
          />

          <add-company-api-key-dialog
            v-bind:companyId="company.id"
            v-if="finishedLoading === true && company !== null && company.prodApiKey === null && company.demoApiKey === null"
            @updateCompany="getCompany"
          />
        </v-row>

        <v-row align="center" column justify="center" class="mt-7">
          <v-row class="mt-2 mb-2"  v-if="loading === true">
            <v-progress-circular indeterminate>
            </v-progress-circular>
          </v-row>

          <company-subscription-details
            v-bind:subscription="subscription"
            v-bind:companyId="company.id"
            v-if="subscription !== null"
          />

          <company-subscription-start-trial
            v-bind:companyId="company.id"
            v-if="finishedLoading === true && company !== null && (company.prodApiKey !== null && company.demoApiKey === null) && subscription === null"
            @updateSubscription="getCompanySubscription"
          />
        </v-row>
      </v-col>

      <v-col cols="12" sm="12">
        <add-user-to-company-dialog v-bind:company="company" v-if="company !== null" @updateUsersList="updateUsersList"/>
      </v-col>

      <v-col cols="12" sm="12">
        <users-list v-bind:companyId="company.id" v-if="company !== null" v-bind:updateUsersList="doTheUpdateOfUserList"/>
      </v-col>
      <!-- <v-col cols="12" sm="9">
        <v-row column>
           <v-row class="mt-2 mb-2">
            <add-customer-dialog v-if="company !== null" :companyId="company.id" />
          </v-row>
        </v-row>
      </v-col>

      <v-col cols="12" sm="9">
        <v-row column>
           <v-row class="mt-2 mb-2">
            <customers-list v-if="company !== null" :companyId="company.id" />
          </v-row>
        </v-row>
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
import * as apiCompanies from '../api/companies.js';
import CompanyDetails from '../components/CompanyDetails';
import CompanyApiKeyDetails from '../components/CompanyApiKeyDetails';
import CompanySubscriptionDetails from '../components/CompanySubscriptionDetails.vue';
import AddCompanyApiKeyDialog from '../components/AddCompanyApiKeyDialog.vue';
import UsersList from '../components/UsersList.vue';
import AddUserToCompanyDialog from '../components/AddUserToCompanyDialog.vue';
import CompanySubscriptionStartTrial from '../components/CompanySubscriptionStartTrial.vue';

export default {
  name: "CompanyDetailsPage",
  components: {
    CompanyDetails,
    CompanyApiKeyDetails,
    CompanySubscriptionDetails,
    AddCompanyApiKeyDialog,
    UsersList,
    AddUserToCompanyDialog,
    CompanySubscriptionStartTrial
  },
  data: function () {
    return {
      companyId: null,
      company: null,
      subscription: null,
      loading: false,
      finishedLoading: false,
      doTheUpdateOfUserList: false
    }
  },
  mounted () {
    this.companyId = this.$route.params.id
    this.updateCompanyInfo();
  },
  methods: {
    updateCompanyInfo() {
      this.getCompany();
      this.getCompanySubscription();
    },
    getCompany() {
      this.loading = true

      apiCompanies.getCompany(this.companyId, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          this.company = responseData;

          this.loading = false
          this.finishedLoading = true
        })
        .catch(() => {
          this.company = null
          console.log("Greska! Nije nadjena kompanija")

          this.loading = false
        })
    },
    getCompanySubscription() {
      this.loadingSubscription = true;

      apiCompanies.getCompanySubscription(this.companyId, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          this.subscription = responseData;
          this.loadingSubscription = false;
        })
        .catch(() => {
          this.subscription = null;
          console.log("Greska! Nije nadjena supskripcija");
          this.loadingSubscription = false;
        })
    },
    updateUsersList() {
      this.doTheUpdateOfUserList = true;
    }
  }
}
</script>
