<template>
  <div>
    <v-dialog v-model="dialogEditCompanyKey" @keydown.esc="onCancel" persistent max-width="700px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="primary" v-bind="attrs" v-on="on">
          Izmeni
          <v-icon>mdi-square-edit-outline</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-btn icon dark @click="onCancel">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            Izmena API ključeva kompanije
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text class="mt-4">
          <v-list dense>
            <v-container>
            <v-row>
              <v-col cols="12">
                <v-row class="mt-4">
                  <v-col cols="12" sm="6" class="pt-0 pb-0">
                    <v-text-field prepend-icon="mdi-export" label="Produkcioni API ključ" outlined v-model="updatedCompanyKey.prodApiKey"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" class="pt-0 pb-0">
                    <v-text-field prepend-icon="mdi-import" label="Demo API ključ" outlined v-model="updatedCompanyKey.demoApiKey"></v-text-field>
                  </v-col>
                  <v-col cols="12" class="pt-0 pb-0">
                    <v-switch label="Demo SEF?" required outlined v-model="updatedCompanyKey.isDemo"></v-switch>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12">
                <v-divider></v-divider>
                <!-- <customers-cust-codes-list :customerId="companyKey.customerSID" /> -->
              </v-col>
            </v-row>
          </v-container>
          </v-list>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="ma-2" color="red" outlined @click="onCancel">
            Odustani
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn class="success ma-2 white--text" text @click="dialogConfirmSave = true" :loading="puttingToApi">
            Sačuvaj izmene
            <v-icon right>mdi-content-save</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogConfirmSave" persistent max-width="600px">
      <v-card>
        <v-card-title>
          Da li želite da sačuvate promene?
        </v-card-title>

         <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="red--text plain ma-2" text @click="dialogConfirmSave = false">
            Odustani
          </v-btn>
          <v-btn class="green--text plain ma-2" text @click="onConfirmEdit" :loading="puttingToApi">
            Sačuvaj
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as apiCompanies from '../api/companies';

export default {
  name: 'EditCompanyApiKeyDialog',
  data: function () {
    return {
      dialogConfirmSave: false,
      dialogEditCompanyKey: false,
      puttingToApi: false,
      deleting: false,
      updatedCompanyKey: {}
    }
  },
  props: {
    company: {
      type: Object,
      required: true
    }
  },
  watch: {
    company: {
      handler() {
        // Kopiranje objekta
        this.copy();
      }
    }
  },
  mounted () {
    this.copy();
  },
  methods: {
    copy() {
      this.updatedCompanyKey.prodApiKey = this.company.prodApiKey;
      this.updatedCompanyKey.demoApiKey = this.company.demoApiKey;
      this.updatedCompanyKey.isDemo = this.company.isDemo;
    },
    onCancel() {
      this.dialogEditCompanyKey = false;
    },
    onConfirmEdit() {
      // TODO: validacija pre slanja na API

      this.puttingToApi = true;

      apiCompanies.changeCompanyKeys(this.company.id, this.updatedCompanyKey, this.$store.getters.token)
        .then(response => response)
        .then(() => {
          // loading na dugmetu
          this.puttingToApi = false;

          // zatvaranje modalnih prozora
          this.dialogConfirmSave = false;
          this.dialogEditCompanyKey = false;

          // brisanje unetih podataka
          this.updatedCompanyKey = {};

          this.$emit('update');
        })
        .catch(() => {
          this.puttingToApi = false;
        });
    },
  }
}
</script>
