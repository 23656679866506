<template>
  <v-container>
    <v-card>
      <v-card-title>
        Korisnici
        <v-spacer></v-spacer>
        <v-text-field
          v-model="queryString"
          append-icon="mdi-magnify"
          label="Pretraga"
          single-line
          hide-details
          clearable
          @keyup.enter.prevent='onEnterSearch'
          @click:clear='onClearSearch()'
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="listOfUsers"
        :loading="loading"
        :footer-props="{
          'items-per-page-options': [10, 20, 30, 40, 50],
          'items-per-page-text': 'Broj redova po stranici:'
        }"
        no-data-text="Nema rezultata"
        loading-text="Učitavanje podataka... Molimo sačekajte"
        class="elevation-1 row-pointer"
        width="100%"
      >
        <template v-slot:[`item.createdAt`]="{ item }">
            {{ getFormattedDate(item.createdAt) }}
        </template>

        <template v-slot:[`item.role`]="{ item }">
            {{ mapRoleIdToRoleName(item.role) }}
        </template>

        <template v-slot:[`item.actions`]="{ item }">
          <v-icon class="mr-2" @click="onRowClick(item)">mdi-eye</v-icon>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import * as apiUsers from '../api/users.js';
import * as utils from '../utils.js';

export default {
  name: "UsersList",
  data: function () {
    return {
      loading: false,
      queryString: null,
      listOfUsers: [],
      headers: [
          { text: 'ID', value: 'id', width: "5%" },
          { text: 'Ime', value: 'firstName', width: "8%" },
          { text: 'Prezime', value: 'lastName', width: "8%" },
          { text: 'Email za login', value: 'username', width: "8%" },
          { text: 'Kontakt email', value: 'contactEmail', width: "8%" },
          { text: 'Telefon', value: 'phone', width: "12%", sortable: false },
          { text: 'Uloga', value: 'role', width: "8%" },
          { text: 'FirebaseID', value: 'firebaseId' },
          { text: 'Datum dodavanja', value: 'createdAt', width: '10%' },
          //{ text: '', value: 'actions', width:"3%", sortable: false }
      ]
    }
  },
  props: {
    companyId: {
      type: Number,
      required: true,
    },
    updateUsersList: {
      type: Boolean,
      required: true
    }
  },
  watch: {
     updateUsersList: {
      handler () {
        if (this.updateUsersList === true) {
          this.getUsersFromAPI();
        }
      },
      deep: true,
    }
  },
  mounted () {
    this.getUsersFromAPI();
  },
  methods: {
    getUsersFromAPI() {
      this.loading = true;

      apiUsers.getUsersOfCompany(this.companyId, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          this.listOfUsers = responseData;
          this.loading = false;
        })
        .catch(() => {
          this.listOfUsers = [];
          this.loading = false;
        })
    },

    getFormattedDate(dateStr) {
      return utils.formatDate(dateStr);
    },

    mapRoleIdToRoleName(roleId) {
      switch (roleId) {
        case 0: return 'Vlasnik';
        case 1: return 'Zaposleni';
        default: return 'Nepoznato';
      }
    },

    onRowClick(user) {
      let route = this.$router.resolve({ path: '/users/' + user.id });
      window.open(route.href, '_blank');
    },

    getAvatarColor(item) {
      if (item.active === 1)
        return "success";
      else
        return "error";
    },
  }
}
</script>
