import * as fetch from './fetch'

export function logIn(loginRequest) {
  return fetch.POST("/auth/login", null, JSON.stringify(loginRequest), false)
}

export function addUserToCompany(companyId, newUserRequest, token) {
  const endpoint = '/users/ofCompany/' + companyId;
  return fetch.POST(endpoint, token, newUserRequest, true);
}

export function getUsersOfCompany(companyId, token) {
  const endpoint = `/users/ofCompany/${companyId}`;
  return fetch.GET(endpoint, token, true);
}

