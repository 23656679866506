<template>
  <v-card v-if="loading === false" width="100%" class="ml-7">
    <v-card-title>
      Pretplata
    </v-card-title>

    <v-card-subtitle>
      <v-chip text-color="white" :color="subscriptionStateColor">
            {{ subscriptionStateText }}
          </v-chip>
    </v-card-subtitle>

    <v-card-text>
      <v-row>
        <v-col cols="12" md="6">
           <v-list dense>
              <v-list-item v-for="item in subscriptionInformation" :key="item.label">
                <v-list-item-icon>
                  <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                  <v-list-item-title>{{ item.label }}</v-list-item-title>
                  <v-list-item-subtitle>{{ getSubscriptionProperty(item.propertyName) }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
           </v-list>
        </v-col>
        <v-col cols="12" md="6">
           <company-subscription-prolong :companyId="companyId" />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import * as utils from '../utils.js';
import CompanySubscriptionProlong from '../components/CompanySubscriptionProlong.vue';

export default {
  name: 'CompanySubscriptionDetails',
  components: {
    CompanySubscriptionProlong
  },
  data: function () {
    return {
      loading: false,
      subscriptionInformation: [
        { label: "Datum isteka", propertyName: 'validUntil', icon: "mdi-calendar" },
      ]
    }
  },
  props: {
    subscription: {
      type: Object,
      required: true
    },
    companyId: {
      type: Number,
      required: true
    }
  },
  computed: {
    subscriptionStateText() {
      return utils.getSubscriptionStateText(this.subscription.state);
    },
    subscriptionStateColor() {
      return utils.getSubscriptionStateColor(this.subscription.state);
    },
  },
  methods: {
    getSubscriptionProperty(propertyName) {
      if (propertyName === 'createdAt' || propertyName === "validUntil")
        return utils.formatDate(this.subscription[propertyName]);
      else if (propertyName === "package")
        return utils.getSubscriptionPackageText(this.subscription.package);
      else
        return this.subscription[propertyName];
    },
    updateParentComponent (companyId) {
      this.$emit('updateCompany', companyId);
    },
  }
}
</script>
