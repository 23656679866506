<template>
  <div>
    <v-dialog v-model="dialogEditCompany"  @keydown.esc="onCancel" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="primary mx-2" dark v-bind="attrs" v-on="on">
          Izmeni
          <v-icon>mdi-square-edit-outline</v-icon>
        </v-btn>
      </template>
      <v-card>
        <v-toolbar dense dark color="primary">
          <v-btn icon dark @click="onCancel" >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            Izmena podataka
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-card-text class="mt-4">
          <v-list dense>
            <v-list-item v-for="item in userInformation" :key="item.label">
              <v-text-field class="pt-0 pb-0" :prepend-icon="item.icon" :label="item.label" v-model="updatedTempCompany[item.value]" ></v-text-field>
            </v-list-item>
          </v-list>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="error ma-2 white--text" text @click="onCancel">
            Odustani
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn class="success ma-2 white--text" text @click="dialogConfirmSave = true" :loading="puttingToApi">
            Sačuvaj izmene
            <v-icon right>mdi-content-save</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogConfirmSave" persistent max-width="600px">
      <v-card>
        <v-card-title>
          Da li želite da sačuvate promene?
        </v-card-title>

         <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="red--text plain ma-2" text @click="dialogConfirmSave = false">
            Odustani
          </v-btn>
          <v-btn class="green--text plain ma-2" text @click="onConfirmEdit" :loading="puttingToApi">
            Sačuvaj
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import * as companiesApi from '../api/companies'

export default {
  name: 'EditCompanyDialog',
  data: function () {
    return {
      dialogEditCompany: false,
      dialogConfirmSave: false,
      puttingToApi: false,
      updatedTempCompany: {},
      userInformation: [
        { label: "Ime", value: "firstName", icon: "mdi-account" },
        { label: "Prezime", value: "lastName", icon: "mdi-account" },
        { label: "Kontakt email", value: "contactEmail", icon: "mdi-card-account-mail" },
        { label: "Broj telefona", value: "phone", icon: "mdi-phone" },
      ]
    }
  },
  props: {
    tempCompany: {
      type: Object,
      required: true
    }
  },
  watch: {
    dialogEditCompany(newValue) {
      if (newValue === true) {
        this.shallowCopy();
      }
    }
  },
  mounted () {
    // Kopiranje objekta
    this.shallowCopy();
  },
  methods: {
    shallowCopy() {
      this.updatedTempCompany = JSON.parse(JSON.stringify(this.tempCompany));
    },
    onCancel() {
      this.shallowCopy();
      this.dialogEditCompany = false;
    },
    onConfirmEdit() {
      // TODO: validacija pre slanja na API

      this.puttingToApi = true;

      companiesApi.putTempCompany(this.tempCompany.id, this.updatedTempCompany, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          // loading na dugmetu
          this.puttingToApi = false;

          // zatvaranje modalnih prozora
          this.dialogConfirmSave = false;
          this.dialogEditCompany = false;

          // brisanje unetih podataka za novu kompaniju
          this.updatedTempCompany = {};

          this.$emit('update', responseData.id);
        })
        .catch(() => {
          this.puttingToApi = false;
        });
    }
  }
}
</script>
