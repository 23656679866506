<template>
  <div>
    <v-container class="mt-6">
      <v-row align="center" justify="center">
        <v-col cols="12" sm="6" class="mt-6">
          <v-layout align-center column justify-center class="mt-6">
            <v-card>
              <v-card-text>
                <v-text-field label="Email" v-model="email" />
                <v-text-field label="Password" type="password" v-model="password" @keyup.enter="onClickEnter()"/>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn @click="loginToFirebase" type="Submit" :loading="loading">Log In</v-btn>
              </v-card-actions>
            </v-card>
            <v-alert type="error" v-if="loginFailure">Pogrešan email ili lozinka. Probajte opet.</v-alert>
          </v-layout>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import * as apiUsers from '../api/users.js';
import firebase from "firebase";

export default {
  name: 'Login',
  data: function () {
    return {
      email: '',
      password: '',
      firebaseToken: null,
      loginFailure: false,
      loading: false
    }
  },
  mounted() {
    console.log(process.env.VUE_APP_VERSION);
  },
  methods: {
    onClickEnter () {
      this.loginToFirebase();
    },
    root () {
      if (this.$router.currentRoute.path !== '/') {
        this.$router.push('/')
        return
      }

      if (this.$store.state.getters.isLoggedIn === false) {
        this.$router.push('/login')
      }
    },
    loginToFirebase() {
      this.loading = true;

      firebase
      .auth()
      .signInWithEmailAndPassword(this.email, this.password)
        .then((responseData) => {
          this.firebaseToken = responseData.user.Aa;
          this.login();
        })
        .catch((error) => {
          alert(error.message);
          this.loading = false;
        });
    },
    login () {
      this.loading = true;

      let loginRequest = {
        idToken: this.firebaseToken,
        osVersion: navigator.userAgent,
        deviceType: 3,
        appVersion: 'Admin-' + process.env.VUE_APP_VERSION
      };

      apiUsers.logIn(loginRequest)
        .then(response => response.json())
        .then(data => {
          //localStorage.setItem('user-token', data.token)
          this.loading = false;
          this.$store.commit('token', data.token);
          this.root();
        })
        .catch(() => {
          this.loading = false;
          this.$store.commit('token', null)
          this.showLoginError()
        })
    },

    showLoginError () {
      this.loginFailure = true

      setTimeout(() => {
        this.loginFailure = false
      }, 3000)
    }
  }
}

</script>
