<template>
  <v-container fill-height>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="3">
        <!-- <img width="400px" src="../assets/altermedia-logo.png"> -->
        <v-img :src="sefLinkLogo" max-height="120">
        </v-img>
      </v-col>
    </v-row>

     <v-row align="center" justify="center">
      <v-col cols="12" sm="2">
        <!-- <img width="400px" src="../assets/altermedia-logo.png"> -->
        <v-img :src="altermediaLogo" max-height="120">
        </v-img>
      </v-col>
   </v-row>

    <v-row align="bottom" justify="center">
      <v-col cols="12">
        <div class="text-center">
          verzija {{ appVersion }}
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Home',
  data: function () {
    return {
      altermediaLogo: require('../assets/altermedia-logo.png'),
      sefLinkLogo: require('../assets/SefLinkLogo.svg'),
      appVersion: process.env.VUE_APP_VERSION
    }
  }
}
</script>
