<template>
  <v-navigation-drawer app flat dark permanent clipped :mini-variant.sync="openDrawer">
    <v-list dense nav>
      <v-list-item
        v-for="item in links"
        :key="item.text"
        link
        v-bind:disabled="item.disabled"
        :to="item.to"
      >
        <v-list-item-icon>
          <v-icon>{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <template v-slot:append>
      <v-btn icon @click.stop="openDrawer = !openDrawer">
        <v-icon v-if="!openDrawer">mdi-chevron-left</v-icon>
        <v-icon v-else>mdi-chevron-right</v-icon>
      </v-btn>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'Drawer',
  data: function () {
    return {
      links: [
      {
        to: "/companies",
        icon: "mdi-domain",
        text: "Kompanije",
        disabled: false
      },
      {
        to: "/users",
        icon: "mdi-account",
        text: "Korisnici",
        disabled: true
      },
      {
        to: "",
        icon: "mdi-cog",
        text: "Podešavanja",
        disabled: true
      }
      ],
      openDrawer: true
    }
  }
}
</script>

<style scoped>
.navmenu-item{
  margin-left: 15px;
}
</style>

