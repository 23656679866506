<template>
  <v-container>
    <v-card>
      <v-card-title>
        Korisnici koji nisu završili registraciju
      </v-card-title>
      <v-card-subtitle>
        <v-row justify="end">
          <v-col cols="12" sm="4" class="py-0">
            <v-text-field
              v-model="queryString"
              append-icon="mdi-magnify"
              label="Pretraga"
              single-line
              hide-details
              clearable
              @keyup.enter.prevent='onEnterSearch'
              @click:clear='onClearSearch()'
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-subtitle>
      <v-data-table
      :headers="headers"
      :items="listOfCompanies"
      :server-items-length="totalCompaniesCount"
      :options.sync="options"
      :loading="loading"
      :footer-props="{
        'items-per-page-options': [10, 20, 30, 40, 50],
        'items-per-page-text': 'Broj redova po stranici:'
      }"
      no-data-text="Nema rezultata"
      loading-text="Učitavanje podataka... Molimo sačekajte"
      class="elevation-1 row-pointer"
      width="100%"
      @click:row="onRowClick"
      >
        <template v-slot:[`item.active`]="{ item }">
          <v-avatar :color="getActiveColor(item)" size="15"></v-avatar>
        </template>

        <template v-slot:[`item.userFullName`]="{ item }">
          {{ item.firstName + ' ' + item.lastName }}
        </template>

      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import * as apiCompanies from '../api/companies.js';
import * as utils from '../utils.js';

export default {
  name: "TempCompaniesList",
  data: function () {
    return {
      loading: false,
      options: {
        sortDesc: [true]
      },
      queryString: null,
      isSearchByID: false,
      listOfCompanies: [],
      totalCompaniesCount: 0,
      headers: [
          { text: 'ID', value: 'id', width: "5%", sortable: false },
          { text: 'PIB', value: 'tid', width: "8%", sortable: false },
          { text: 'MB', value: 'mn', width: "7%", sortable: false },
          { text: 'Naziv', value: 'name', width: "25%", sortable: false },
          { text: 'Adresa', value: 'address', width: "20%", sortable: false },
          { text: 'Grad', value: 'town', width: "10%", sortable: false },
          { text: 'FirebaseID', value: 'firebaseId', width: "10%", sortable: false },
          { text: 'Ime i prezime', value: 'userFullName', width: "10%", sortable: false },
          { text: 'Kontakt email', value: 'contactEmail', width: "10%", sortable: false },
          { text: 'Telefon', value: 'phone', width: "10%", sortable: false },
          { text: 'Email za login', value: 'username', width: "8%" },
      ]
    }
  },
  watch: {
    options: {
      handler () {
        this.options.search = this.queryString;
        this.getCompaniesFromAPI()
      },
      deep: true,
    }
  },
  mounted () {
    this.getCompaniesFromAPI()
  },
  methods: {
    getCompaniesFromAPI() {
      this.loading = true

      apiCompanies.getTempCompaniesList(this.options, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          this.loading = false;
          this.listOfCompanies = responseData.companies;
          this.totalCompaniesCount = responseData.totalCount;
        })
        .catch(() => {
          this.listOfCompanies = [];
          this.loading = false;
          this.totalCompaniesCount = 0;
        })
    },

    getSubscriptionStateText(subscriptionState) {
      return utils.getSubscriptionStateText(subscriptionState);
    },

    onEnterSearch() {
      this.options.search = this.queryString
      this.options.page = 1
      this.getCompaniesFromAPI()
    },

    onClearSearch() {
      this.options.search = ''
      this.queryString = ''
      this.getCompaniesFromAPI()
    },

    onSwitchSearch() {
      this.options.page = 1;
      this.options.isSearchByID = this.isSearchByID;
      this.options.search = this.queryString;
      this.getCompaniesFromAPI();
    },

    getActiveColor(item) {
      if (item.active)
        return "success";
      else
        return "error";
    },

    onRowClick(tempCompany) {
      let route = this.$router.resolve({ path: '/tempCompanies/' + tempCompany.id });
      window.open(route.href, '_blank');
    },

  }
}
</script>

<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
