<template>
  <v-container class="mt-5 ml-5">
    <v-row align="center" justify="center">
      <v-col cols="12" lg="6" sm="6">
        <v-row align-center column justify-center>
          <v-row class="mt-2 mb-2"  v-if="loading === true">
            <v-progress-circular indeterminate>
            </v-progress-circular>
          </v-row>

          <temp-company-details
            v-bind:company="tempCompany" v-if="finishedLoading === true"
            @updateCompany="getTempCompany"
          />
        </v-row>
      </v-col>
      <!-- <v-col cols="12" sm="9">
        <v-row column>
           <v-row class="mt-2 mb-2">
            <add-customer-dialog v-if="tempCompany !== null" :companyId="tempCompany.id" />
          </v-row>
        </v-row>
      </v-col>

      <v-col cols="12" sm="9">
        <v-row column>
           <v-row class="mt-2 mb-2">
            <customers-list v-if="tempCompany !== null" :companyId="tempCompany.id" />
          </v-row>
        </v-row>
      </v-col> -->
    </v-row>
  </v-container>
</template>

<script>
import * as apiCompanies from '../api/companies.js';
import TempCompanyDetails from '../components/TempCompanyDetails';
// import CompanyApiKeyDetails from '../components/CompanyApiKeyDetails';
// import CompanySubscriptionDetails from '../components/CompanySubscriptionDetails.vue';
// import UsersList from '../components/UsersList.vue';
// import AddUserToCompanyDialog from '../components/AddUserToCompanyDialog.vue';
// import CompanySubscriptionStartTrial from '../components/CompanySubscriptionStartTrial.vue';

export default {
  name: "TempCompanyDetailsPage",
  components: {
    TempCompanyDetails,
  },
  data: function () {
    return {
      tempCompanyId: null,
      tempCompany: null,
      loading: false,
      finishedLoading: false,
      doTheUpdateOfUserList: false
    }
  },
  mounted () {
    this.tempCompanyId = this.$route.params.id
    this.updateTempCompanyInfo();
  },
  methods: {
    updateTempCompanyInfo() {
      this.getTempCompany();
    },
    getTempCompany() {
      this.loading = true

      apiCompanies.getTempCompany(this.tempCompanyId, this.$store.getters.token)
        .then(response => response.json())
        .then(responseData => {
          this.tempCompany = responseData;

          this.loading = false
          this.finishedLoading = true
        })
        .catch(() => {
          this.tempCompany = null
          alert("Greska! Nije nadjena kompanija")

          this.loading = false
        })
    }
  }
}
</script>
