import moment from 'moment';

export function getSubscriptionStateText(stateStr) {
  switch (stateStr) {
    case 'Inactive': return "Neaktivan";
    case 'Registered': return "Registrovan";
    case 'Trial': return "Probni period";
    case 'Activated': return "Aktiviran";
    case 'Extended': return "Produžen";
  }
}

export function getSubscriptionStateColor(stateStr) {
  switch (stateStr) {
    case 'Inactive': return "red";
    case 'Registered': return "grey";
    case 'Trial': return "blue";
    case 'Activated': return "green";
    case 'Extended': return "orange";
  }
}

export function getSubscriptionPackageText(packageInt) {
  switch (packageInt) {
    case 0: return "Osnovni";
  }
}

export function formatDate(value) {
  if (value) {
    let dateString = moment(String(value)).format('DD.MM.YYYY HH:mm:ss');
    if (dateString === "01.01.0001") {
      return null;
    }
    else {
      return dateString;
    }
  }
}

export function getCompanyApiKeyValidatedTextValue(companyValidated) {
  switch (companyValidated) {
    case 0: return "Nevalidiran";
    case 1: return "Nevalidan";
    case 2: return "Validan";
    default: return "Nepoznato";
  }
}
