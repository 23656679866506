import * as fetch from './fetch'

export function prolongSubscription(request, token) {
  const endpoint = '/subscriptions/prolong';
  return fetch.POST(endpoint, token, request, true);
}

export function startTrial(request, token) {
  const endpoint = '/subscriptions/trial';
  return fetch.POST(endpoint, token, request, true);
}

export function getExpiringSubscriptionsList(days, token) {
  const endpoint = '/subscriptions/expiring/' + days;
  return fetch.GET(endpoint, token, true);
}
