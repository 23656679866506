<template>
  <v-card v-if="loading === false" width="100%">
    <v-card-title>
      {{ this.company.name }}
    </v-card-title>

    <v-card-text>
      <v-row>
        <v-col cols="12" md="6">
           <v-list dense>
            <v-list-item v-for="item in companyInformation" :key="item.label">
              <v-list-item-icon>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ item.label }}</v-list-item-title>
                <v-list-item-subtitle>{{ getCompanyProperty(item.propertyName) }}</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-chip text-color="white" :color="this.company.active ? 'green' : 'red'">
            Status kompanije: {{ this.company.active ? 'Aktivna' : 'Neaktivna' }}
          </v-chip>
        </v-col>
      </v-row>
    </v-card-text>

    <v-card-actions>
      <!-- <edit-company-dialog :company="company" v-if="loading === false" @update="updateParentComponent"/> -->
    </v-card-actions>
  </v-card>
</template>

<script>
// import EditCompanyDialog from './EditCompanyDialog';
import * as utils from '../utils.js';

export default {
  name: 'CompanyDetails',
  // components: {
  //   EditCompanyDialog
  // },
  data: function () {
    return {
      loading: false,
      companyInformation: [
        { label: "PIB", propertyName: 'tid', icon: "mdi-fingerprint" },
        { label: "MB", propertyName: 'mn', icon: "mdi-fingerprint" },
        { label: "Država", propertyName: 'country', icon: "mdi-flag" },
        { label: "Grad", propertyName: 'town', icon: "mdi-city-variant" },
        { label: "Poštanski broj", propertyName: 'postalCode', icon: 'mdi-mailbox' },
        { label: "Adresa", propertyName: 'address', icon: "mdi-map-marker" },
        { label: "Datum dodavanja", propertyName: 'createdAt', icon: "mdi-calendar" },
      ]
    }
  },
  props: {
    company: {
      type: Object,
      required: true
    }
  },
  methods: {
    getCompanyProperty(propertyName) {
      if (propertyName === 'createdAt')
        return utils.formatDate(this.company[propertyName]);
      else
        return this.company[propertyName];
    },
    updateParentComponent (companyId) {
      this.$emit('updateCompany', companyId);
    }
  }
}
</script>
